import React from "react"
import Layout from "../components/layout"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import { css } from "@emotion/core"

export const query = graphql`
  query {
    file(relativePath: { eq: "updatedPyramid.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 825) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const Pyramid = ({ data }) => (
  <Layout>
    
    <div
      css={css`
        width: 100%;
        @media (min-width: 600px) {
          width: 80%;
        }
        margin-right: 40px;
        padding-right: 40px;
      `}
    >
      <h1
         css={css`
         color: navy;
         `}
      >Programmer’s Pyramid</h1>

      <Img
        className="headshot"
        fluid={data.file.childImageSharp.fluid}
        alt="pyramid"
        css={css`
      `}
      />
     

      <br />
      <div>
        <p>
        <a href="https://programmerspyramid.com/">Programmer’s Pyramid</a> is your <span style={{ fontWeight: "bold" }}>roadmap</span> for learning to program effectively.
        </p>

        <p>
        This <span style={{ fontStyle: "italic" }}>free</span> online resource teaches the fundamentals of programming.</p>
        <ul>
          <li><span style={{ fontWeight: "bold" }}>Build</span> a solid foundation of knowledge.</li>
          <li><span style={{ fontWeight: "bold" }}>Develop</span> core skills.</li>
          <li><span style={{ fontWeight: "bold" }}>Obtain</span> an effective learning approach.</li>
        </ul>
       
        <p>
        <a href="https://programmerspyramid.com/">Access the Pyramid for free.</a>
        </p>
        
       
      </div>
    </div>
  </Layout>
)

export default Pyramid
